<script>
  import Carousel from "../components/Carousel.svelte";
  import Container from "../components/Container.svelte";
  import ImageCarouselCard from "../components/ImageCarouselCard.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import Section from "../components/Section.svelte";
  import { galleriesByName } from "../galleries/compiled";
  import { undrawImageAlt } from "../undrawImageAlts";

  const talks = [
    {
      title: "Lead Like You Mean It",
      description:
        "A compelling exploration of authentic leadership that empowers participants to identify their unique leadership style and leverage it for maximum impact.",
      img: "the-world-is-mine_wnib",
    },
    {
      title: "Playful Leadership",
      description:
        "A learning adventure that demonstrates how incorporating playfulness into leadership can drive creativity, boost engagement, and transform team dynamics.",
      img: "powerful_e1sw",
    },
    {
      title: "Clarity From Complexity",
      description:
        "An engaging keynote that equips leaders with powerful mental models to break down interconnected challenges, plan meaningful change, and build more resilient teams and processes.",
      img: "mind-map_i9bv",
    },
  ];

  const gallery = galleriesByName["speaking-andre"];
</script>

<Section bgColor="dark-grey">
  <Container>
    <img alt="andre" height="306" src="/img/speaking-andre.png" width="306" />
    <h1>Andre Ben Hamou</h1>
    <p class="strapline">Leadership Speaker</p>
  </Container>
</Section>

<Section bgColor="platinum">
  <Container>
    <div class="columns">
      <div class="column is-half copy">
        Andre is a dynamic speaker and leadership expert who brings over twenty
        years of high-performance technology leadership experience to the stage.
        With an engaging, interactive style, Andre transforms complex leadership
        concepts into accessible, actionable insights that resonate with
        audiences across industries.
      </div>
      <div class="column is-half copy">
        Rising from software developer to Director of Engineering at Riot Games
        where he led a 400-person engineering team, Andre's leadership journey
        continued through executive roles at Facebook and LinkedIn. He
        particularly enjoys speaking with Engineering and Product audiences,
        where his experience enables him to bridge the gap between theory and
        application.
      </div>
    </div>
  </Container>
</Section>

<Section>
  <Container>
    <p>
      Andre specializes in three <strong>interactive talks</strong> that challenge
      traditional leadership thinking...
    </p>

    <div class="columns">
      {#each talks as t}
        <div class="column">
          <img
            alt={undrawImageAlt(t.img)}
            class="mb1r"
            src="/img/illustrations/gold/undraw_{t.img}.svg"
            style="height:150px"
          />
          <h2>{t.title}</h2>
          <p>{t.description}</p>
        </div>
      {/each}
    </div>

    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <em>
          He is also regularly asked to create bespoke talks on subjects around
          leadership and organizational culture along with many other
          workplace/business related topics.
        </em>

        <LogoGrid
          partners={["riot", "wall-street-journal", "atlassian", "facebook"]}
        />
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="gold">
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <p>
          <strong>
            Andre is available for virtual engagements around the world.
          </strong>
        </p>
        <a
          class="button mail is-dark is-medium"
          href="mailto:andre@peoplestorming"
          rel="noopener"
          target="_blank"
        >
          Get in touch
        </a>
        <small>
          remember to include details about your event and audience
        </small>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="platinum">
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        Andre is a sought-after speaker for corporate events including product
        launches, company all-hands, team retreats, and more. He has also
        delivered thought-provoking presentations at Culture Summit, FailCon,
        AIN17, and Tech Inclusion.
      </div>
    </div>
    <Carousel
      auto
      component={ImageCarouselCard}
      items={gallery}
      style="height:348px;"
    />
  </Container>
</Section>

<style>
  a:not(:hover):not(.button) {
    text-decoration: underline;
  }

  .copy {
    display: block;
    text-align: left;
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-size: var(--ps-fontsize-medium);
  }

  small {
    font-style: italic;
    margin-top: 0.5rem;
    max-width: 16rem;
  }
</style>
