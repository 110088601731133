<script>
  import Section from "./Section.svelte";
  import SocialIcons from "./SocialIcons.svelte";

  export let route;

  let disabled = ["/leadership-speaker"].includes(route.path);
</script>

{#if !disabled}
  <Section squarePad>
    <div class="container">
      <img
        src="/img/logo.png"
        srcset="/img/logo.png 1x, /img/logo-1.5x.png 1.5x, /img/logo-2x.png 2x, /img/logo-3x.png 3x"
        alt="peoplestorming logo"
      />
      <div class="buttons">
        <SocialIcons />
        <a
          class="button mail is-gold"
          href="mailto:hello@peoplestorming"
          rel="noopener"
          target="_blank"
        >
          Get in touch
        </a>
      </div>
    </div>
  </Section>
{/if}

<style>
  div.container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  img {
    height: 30px;
    width: 150px;
  }

  .mail {
    margin-left: 1rem;
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 450px) {
    .buttons :global(a) {
      display: none;
    }

    .buttons a {
      display: block;
    }
  }
</style>
