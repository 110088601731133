<script>
  import NavLink from "./NavLink.svelte";
</script>

<table>
  <thead>
    <tr>
      <td class="blank" />
      <th>
        Essentials
        <br />
        <aside>2-4 mths</aside>
      </th>
      <th>
        Core
        <br />
        <aside>4-6 mths</aside>
      </th>
      <th>
        Boosted
        <br />
        <aside>6-9 mths</aside>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th colspan="4">
        Group Workshops (2hrs)
        <!-- <ul>
          <li>
            Live, virtual sessions facilitated by industry experienced coaches
          </li>
          <li>Workbooks, tools and frameworks</li>
          <li>
            Small group exercises, whole group discussions, experiments and more
          </li>
        </ul> -->
      </th>
    </tr>
    <tr>
      <td>
        <NavLink
          to="/w/coaching-workshop"
          style="text-decoration-line:underline"
        >
          Coaching Fundamentals
        </NavLink>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        <NavLink to="/w/1on1s-and-pdps" style="text-decoration-line:underline">
          Effective 1-on-1s
        </NavLink>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        <NavLink to="/w/feedback" style="text-decoration-line:underline">
          Feedback
        </NavLink>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        <NavLink to="/w/decision-making" style="text-decoration-line:underline">
          Decision Making & Accountability
        </NavLink>
      </td>
      <td />
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        <NavLink to="/w/productivity" style="text-decoration-line:underline">
          Mindful Productivity
        </NavLink>
      </td>
      <td />
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        Option To Exchange Topics
        <br />
        <aside>
          <NavLink to="/w/psych-safety" style="text-decoration-line:underline">
            Psychological Safety
          </NavLink>
          &middot;
          <NavLink
            to="/w/conflict-workshop"
            style="text-decoration-line:underline"
          >
            Conflict Skills
          </NavLink>
        </aside>
      </td>
      <td />
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <th colspan="4">
        Group Roundtables (1hr)
        <!-- <ul>
          <li>
            Whole-group gatherings with pre-session prompts to generate
            high-quality discussion
          </li>
          <li>Structured conversation around program topics</li>
          <li>
            Guided peer-to-peer learning encouraging cross pollination of ideas
          </li>
          <li>Develops a manager support network</li>
        </ul> -->
      </th>
    </tr>
    <tr>
      <td>Paired with each workshop</td>
      <td>3</td>
      <td>5</td>
      <td>5</td>
    </tr>
    <tr>
      <th colspan="4"> 1-on-1 Sessions </th>
    </tr>
    <tr>
      <td> Kickoff & Graduation Coaching (25m) </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td> On-demand Mentoring (50m) </td>
      <td />
      <td />
      <td>3</td>
    </tr>
    <tr>
      <th colspan="4"> Supporting Materials </th>
    </tr>
    <tr>
      <td>
        Personalized Program Companion
        <br />
        <aside>Actions &middot; Experiments &middot; Progress Notes</aside>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        Detailed Workshop Handouts
        <br />
        <aside>Deck Summaries &middot; Quick Reference Guides</aside>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td>
        Leadership Tools
        <br />
        <aside>Frameworks &middot; Canvases &middot; Worksheets</aside>
      </td>
      <td>✓</td>
      <td>✓</td>
      <td>✓</td>
    </tr>
    <tr>
      <td />
      <td colspan="3">
        <NavLink button buttonClass="is-gold" to="/book-disco-call">
          Book a call to get a quote
        </NavLink>
      </td>
    </tr>
  </tbody>
</table>

<style>
  table {
    border-bottom: 1px solid gray;
    width: 100%;
  }

  th {
    padding: 1rem;
  }

  thead th:nth-of-type(1) {
    background-color: var(--ps-colors-light-gold);
  }
  thead th:nth-of-type(2) {
    background-color: var(--ps-colors-gold);
  }
  thead th:nth-of-type(3) {
    background-color: var(--ps-colors-dark-gold);
  }

  thead th aside {
    font-size: smaller;
    font-weight: normal;
    text-wrap: nowrap;
  }

  tbody th {
    background-color: var(--ps-colors-platinum);
    border-top: 1px solid gray;
    font-size: medium;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    text-align: left;
  }

  /* tbody th ul {
    font-size: smaller;
    font-weight: normal;
    list-style-type: disc;
    padding-left: 1rem;
    padding-top: 0.25rem;
  } */

  tbody td {
    border-top: 1px solid var(--ps-colors-platinum);
    font-size: medium;
    padding: 0.5rem;
  }

  tbody td:first-child {
    padding-left: 1rem;
    text-align: left;
  }

  tbody td aside {
    font-size: smaller;
    opacity: 75%;
  }

  @media screen and (max-width: 475px) {
    th,
    td {
      font-size: small !important;
    }
  }
</style>
