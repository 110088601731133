<script>
  import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
  import { navigate } from "svelte-routing";
  import { fade } from "svelte/transition";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Modal from "../components/Modal.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import { slices } from "../utils";

  export let initialCount = null;
  export let items;
  export let subset;

  const itemsByCategory = {};
  for (const i of items)
    itemsByCategory[i.category] = (itemsByCategory[i.category] || []).concat([
      i,
    ]);

  const sortedCategories = Object.keys(itemsByCategory).sort((c1, c2) =>
    c1.localeCompare(c2)
  );

  const countsByCategory = {};
  for (const c in itemsByCategory)
    countsByCategory[c] = [
      initialCount || itemsByCategory[c].length,
      itemsByCategory[c].length,
    ];

  const colors = ["54478c", "0db39e", "f29e4c"];
  const colorsByCategory = {};
  for (const c in sortedCategories)
    colorsByCategory[sortedCategories[c]] = colors[c % colors.length];

  let catId = sortedCategories.map((c) => c.toLowerCase()).indexOf(subset);
  if (catId == -1) catId = 0;
  const category = sortedCategories[catId] || sortedCategories[0];

  $: catFilteredItems = items
    .filter((i) => i.category == category)
    .slice(0, countsByCategory[category][0]);
  $: hiddenCount =
    countsByCategory[category][1] - countsByCategory[category][0];
  $: iSlices = slices(catFilteredItems, 3);

  let activeItem = null;
  let modalActive = false;

  function selectCategory(event) {
    const cat = event.target.value.toLowerCase();
    navigate(`why-us-${cat}`);
  }
</script>

<Modal bind:active={modalActive}>
  {#if activeItem}
    <Quote
      citation={activeItem.citationOverride || activeItem.citation}
      photo={activeItem.image}
      paras={activeItem.text.split("\n")}
    />
  {/if}
</Modal>

{#if sortedCategories.length > 1}
  <div class="select">
    <select
      aria-label="select an area"
      on:input={selectCategory}
      style="border: 2px solid #{colorsByCategory[category]}"
      value={category}
    >
      {#each sortedCategories as c}
        <option>{c}</option>
      {/each}
    </select>
  </div>
{/if}

<Section squarePad>
  <Container>
    {#each iSlices as slice}
      <div class="columns">
        {#each slice as i (i.id)}
          <div class="column is-one-third" in:fade={{ duration: 200 }}>
            <div
              class="card"
              style="border-bottom: 5px solid #{colorsByCategory[i.category]}"
            >
              <Quote
                citation={i.citationOverride || i.citation}
                hideIcon
                paras={[i.summary]}
                photo={i.image}
                photoSmall
              />
              <div class="buttons">
                <button
                  class="button"
                  on:click={() => {
                    activeItem = i;
                    modalActive = true;
                  }}
                >
                  Continue reading...
                </button>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/each}
    {#if hiddenCount > 0}
      <button
        on:click={() => (countsByCategory[category][0] += 3)}
        aria-label="show more"
      >
        <Icon i={faCaretDown} />
      </button>
    {/if}
  </Container>
</Section>

<style>
  .card {
    background-color: var(--ps-colors-platinum);
    border-radius: 5px;
    color: var(--ps-colors-jet);
    height: 100%;
    padding: 1rem;
    width: 100%;

    /* matching .column */
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .card .buttons {
    justify-content: center;
    padding-top: 1rem;
  }

  .column {
    padding: 1rem;
  }

  select {
    font-family: var(--ps-fonts-title);
  }
</style>
