<script>
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Our Team</h1>
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns bios">
      <div class="column">
        <img
          alt="rachel"
          class="adaptive-200 rounded"
          height="200"
          src="/img/team/rachel.jpg"
          srcset="/img/team/rachel.jpg 1x, /img/team/rachel-1.5x.jpg 1.5x"
          width="200"
        />
        <h2>Rachel</h2>
        <h3>Co-founder</h3>
        <ul id="rachel">
          <li>
            A proud Ravenclaw with a passion for making work not just
            productive, but joyful.
          </li>
          <li>
            For over 20 years, she's been on a mission to help teams thrive,
            both as a manager and now as co-founder of PeopleStorming.
          </li>
          <li>
            Think of her as your enthusiastic workplace guide, navigating teams
            through communication puzzles and collaboration caves.
          </li>
          <li>
            Her journey started in the corporate world, where she discovered the
            magic of coaching and its power to unlock potential. With a backpack
            full of organizational improv and behavioral psychology tools, she
            then jumped across the pond to a global games studio.
          </li>
          <li>
            There, she helped managers shine brighter, gamified engagement, and
            even taught 1,500 people the life-changing power of "Yes, and..."
            (it's more magical than you think!).
          </li>
          <li>
            Today, she shares her experience and knowledge through workshops,
            coaching, and writing, all with a healthy dose of laughter and
            learning.
          </li>
          <li>
            Whether you're looking to elevate conversations, infuse energy into
            your team, or simply create a safe space for open dialogue, she's
            raising her hand. Build a brighter, happier workplace together, one
            collaborative adventure at a time!
          </li>
          <li>
            P.S. Want to nerd out on all things teamwork? Check out our
            <a href="#signup">newsletter</a>.
          </li>
        </ul>
        <p class="list-head">Certifications & education...</p>
        <ul>
          <li>Management - BA Hons</li>
          <li>Coaching - Post-grad Diploma</li>
          <li>CIPD Certified in HR & Training (equivalent to SHRM)</li>
          <li>Agile Scrum Master (ASM)</li>
          <li>Certified Scrum Product Owner (CSPO)</li>
          <li>Agile Team Facilitation (ICP-ATF)</li>
          <li>Agile Coaching (ICP-ACC)</li>
          <li>Organizational Improvisation Practitioner</li>
          <li>Design Thinking Facilitator</li>
        </ul>
        <div>
          <a
            class="button is-dark"
            href="mailto:rachel@peoplestorming.com"
            rel="noopener"
            target="_blank"
          >
            Get in touch
          </a>
        </div>
      </div>
      <div class="column">
        <img
          alt="andre"
          class="adaptive-200 rounded"
          height="200"
          src="/img/team/andre.jpg"
          srcset="/img/team/andre.jpg 1x, /img/team/andre-1.5x.jpg 1.5x"
          width="200"
        />
        <h2>Andre</h2>
        <h3>Co-founder</h3>
        <p>
          Andre is a seasoned and dedicated coach who draws on over twenty years
          of leadership in high-performance, product-oriented technology teams.
          He got his start as a software developer at an ISP, where he turned
          his experiences into <a
            href="https://www.amazon.com/Practical-System-Administration-Experts-Source/dp/1590598210"
            rel="noopener"
            target="_blank"
          >
            a technology book</a
          >.
        </p>
        <p>
          His subsequent roles saw him scale as a leader, culminating in a
          position as the Director of Engineering for a 400-person engineering
          team at Riot Games. Following his success with scaling teams he moved
          to Director roles at Facebook and then LinkedIn.
        </p>
        <p>
          He has led product engineering across many domains and has deep
          experience with growing leaders, designing organizations, driving
          collaboration, managing change, mapping complex systems, recruiting,
          and leading product and technology strategies.
        </p>
        <p>
          It is this experience that allows Andre to build deep empathy with new
          and seasoned leaders alike. He's taken the journey they're on and is
          never happier than when helping them get to their destination.
        </p>
        <p class="list-head">His superpowers are...</p>
        <ul>
          <li>
            Shaking the snowglobe - challenging people in a playful way that
            gets them thinking.
          </li>
          <li>
            Decomposition - breaking down difficult or complex topics into
            simple explanations.
          </li>
        </ul>
        <p class="list-head">Certifications & education...</p>
        <ul>
          <li>Adaptive Leadership</li>
          <li>Technical Product Management</li>
          <li>Agile & Scrum Methodologies</li>
          <li>Distributed Systems Engineering</li>
          <li>Physics - MSci Hons. (ARCS)</li>
          <li>Nanomaterials - MRes (DIC)</li>
        </ul>
        <div>
          <a
            class="button is-dark"
            href="mailto:andre@peoplestorming.com"
            rel="noopener"
            target="_blank"
          >
            Get in touch
          </a>
        </div>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="light-grey">
  <Container>
    <p>
      Our team also includes a diverse, extended network of trusted facilitators
      and coaches.
    </p>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Values</h2>
    <div class="columns">
      <div class="column">
        <img
          alt={undrawImageAlt("feeling_of_joy_re_cqko")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_feeling_of_joy_re_cqko.svg"
          style="height:150px"
        />
        <h3>Empathy</h3>
        <p>Listen first and seek real connection</p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("joyride_hnno")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_joyride_hnno.svg"
          style="height:150px"
        />
        <h3>Playfulness</h3>
        <p>Be light in spirit and unconstrained in creativity</p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("detailed_examination_re_ieui")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_detailed_examination_re_ieui.svg"
          style="height:150px"
        />
        <h3>Diligence</h3>
        <p>Sweat the right details and act with care</p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("faq_rjoy")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_faq_rjoy.svg"
          style="height:150px"
        />
        <h3>Curiosity</h3>
        <p>Explore the unknown joyfully, bring back understanding</p>
      </div>
    </div>
  </Container>
</Section>

<style>
  .bios .column {
    align-items: flex-start;
    margin-bottom: 2rem;
    text-align: left;
  }

  .bios .column img {
    align-self: center;
  }

  .bios .column p.list-head {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .bios .column a {
    text-decoration-line: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  .bios .column a:hover {
    text-decoration-color: var(--ps-colors-gold);
  }

  .bios .column ul {
    list-style: inherit;
    margin-bottom: 1.5rem;
    padding-left: 2rem;
  }

  .bios .column li {
    margin-bottom: 0.5rem;
  }

  .bios .column ul#rachel {
    list-style: none;
    padding: 0;
  }

  .bios .column ul#rachel li {
    padding-left: 2rem;
    text-indent: -1.6rem;
  }

  .bios .column ul#rachel li:nth-child(1)::before {
    content: "🎉 ";
  }

  .bios .column ul#rachel li:nth-child(2)::before {
    content: "⏳ ";
  }

  .bios .column ul#rachel li:nth-child(3)::before {
    content: "🏔️ ";
  }

  .bios .column ul#rachel li:nth-child(4)::before {
    content: "🪄 ";
  }

  .bios .column ul#rachel li:nth-child(5)::before {
    content: "💃 ";
  }

  .bios .column ul#rachel li:nth-child(6)::before {
    content: "📍 ";
  }

  .bios .column ul#rachel li:nth-child(7)::before {
    content: "🙌 ";
  }

  .bios .column ul#rachel li:nth-child(8)::before {
    content: "🤓 ";
  }
</style>
