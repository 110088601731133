<script>
  export let item;
</script>

<div>
  <img alt={item.alt} src={item.src} />
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    max-height: 300px;
  }
</style>
