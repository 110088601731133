<script>
  export let partners = [];
</script>

<div class="logo-grid">
  {#each partners as partner}
    <img src="/img/partners/{partner}.png" alt={partner} loading="lazy" />
  {/each}
</div>

<style>
  .logo-grid {
    margin-bottom: 1rem;
  }

  .logo-grid img {
    height: 100px;
    width: 100px;
  }
</style>
