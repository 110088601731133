<script>
  import Container from "./Container.svelte";
  import NavBarNode from "./NavBarNode.svelte";

  import { routesByPath } from "../routes";
  const topRoutePaths = routesByPath[""].children;

  export let route;

  let burgerIsActive = false;
  function burgerToggle() {
    burgerIsActive = !burgerIsActive;
  }

  function onClick() {
    burgerIsActive = false;
  }

  let disabled = ["/leadership-speaker"].includes(route.path);
</script>

{#if !disabled}
  <nav class="navbar">
    <Container>
      <div class="navbar-brand">
        <div
          class="navbar-burger burger"
          class:is-active={burgerIsActive}
          on:click={burgerToggle}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>

      <div class="navbar-menu" class:is-active={burgerIsActive}>
        <div class="navbar-start">
          {#each topRoutePaths as path}
            <NavBarNode route={routesByPath[path]} {onClick} />
          {/each}
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons" />
          </div>
        </div>
      </div>
    </Container>
  </nav>
{/if}

<style>
  nav {
    font-size: var(--ps-fontsize-tiny);
    padding: 0 1.5rem;
  }

  nav :global(.icon) {
    margin: inherit !important;
  }

  nav :global(a) {
    color: var(--ps-colors-jet);
  }
</style>
