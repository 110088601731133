<script>
  import Section from "./Section.svelte";
  import SocialIcons from "./SocialIcons.svelte";
</script>

<style>
  div.container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  img {
    height: 30px;
    width: 150px;
  }

  .mail {
    margin-left: 1rem;
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 450px) {
    .buttons :global(a) {
      display: none;
    }

    .buttons a {
      display: block;
    }
  }
</style>

<Section squarePad>
  <div class="container">
    <img src="/img/logo.png" alt="peoplestorming logo" />
    <div class="buttons">
      <SocialIcons />
      <a
        class="button mail is-gold"
        href="mailto:hello@peoplestorming"
        rel="noopener"
        target="_blank">
        Get in touch
      </a>
    </div>
  </div>
</Section>
