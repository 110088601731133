<script>
  import AirtableSubscribe from "./AirtableSubscribe.svelte";
  import SocialIcons from "./SocialIcons.svelte";

  export let route;

  $: signupConfig = {
    "/burnout-guide": {
      cta: "Get access",
      description: "Get access to our burnout guide by joining our community",
    },
    "/dtog": { hide: true },
    "/signup": { hide: true },
    "/signup-thanks": { hide: true },
    "/tools": { hide: true },
    "/tools-fulfill": { hide: true },
  }[route.path] || { cta: "Sign up" };

  let disabled = [
    "/book-call",
    "/book-disco-call",
    "/buy-coaching-plan",
    "/leadership-speaker",
  ].includes(route.path);

  // let signupVisible = false;
</script>

{#if !disabled}
  <footer class="footer">
    {#if !signupConfig.hide}
      <a name="signup">
        <div class="signup color-bg-light-gold">
          <p>
            {#if signupConfig.description}
              {signupConfig.description}
            {:else}
              Get tips, tools and inspiration on <strong>
                collaboration, communication and workplace culture
              </strong> to your inbox, twice a month.
            {/if}
          </p>

          <!-- {#if signupVisible}
          <div class="spinner">
            <AirtableSubscribe />
          </div>
        {:else}
          <a
            class="button is-dark is-medium"
            href="#signup"
            on:click={() => {
              signupVisible = true;
            }}
          >
            {signupConfig.cta}
          </a>
        {/if} -->

          <AirtableSubscribe
            color="dark"
            cta={signupConfig.cta}
            size="medium"
          />
        </div>
      </a>
    {/if}
    <p>
      <a href="mailto:hello@peoplestorming" rel="noopener" target="_blank">
        hello@peoplestorming.com
      </a>
    </p>
    <p>
      <SocialIcons />
    </p>
    <p id="copyright">
      &copy; 2018-{new Date().getFullYear()} by PeopleStorming
    </p>
  </footer>
{/if}

<style>
  footer {
    font-size: var(--ps-fontsize-small);
    text-align: center;
  }

  footer p {
    margin-bottom: 1rem;
  }

  footer #copyright {
    font-size: var(--ps-fontsize-tiny);
  }

  footer .signup {
    border-radius: 5px;
    margin: 0 auto 1.5rem;
    max-width: 432px;
    padding: 1rem;
  }

  /* footer .spinner {
    background-image: url(/img/icon-spinning.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 200px;
  } */
</style>
