<script>
  import { airtableData } from "../airtable/compiled";
  import Icon from "../components/Icon.svelte";
  import NavLink from "../components/NavLink.svelte";

  export let filter = (w) => w;
  export let forceGray = false;
  export let groupBy = (w) => w.category;
  export let groups = Array.from(
    new Set(airtableData.workshops.map(groupBy))
  ).sort((g1, g2) => g1.localeCompare(g2));
  export let groupFormatter = (g) => g;
  export let icons = (w) => null;
  export let progStub;
  export let showAllWorkshops = false;

  $: colors = forceGray
    ? ["d3d3d3"] // light-grey
    : groups.length > 2
    ? ["badbff", "b8f1f8", "d6fffe", "fae6ff", "b8b9de", "dfc2f2"]
    : [
        "ffd673", // light-gold
        "d3d3d3", // light-grey
      ];

  $: colorsByGroup = {};
  $: for (const i in groups)
    colorsByGroup[groups[i]] = colors[i % colors.length];

  const workshops = (group) =>
    airtableData.workshops
      .filter(filter)
      .filter((w) => groupBy(w) == group)
      .filter(
        (w) =>
          !progStub ||
          w.programsS1.map((p) => p.stub).includes(progStub) ||
          w.programsS2.map((p) => p.stub).includes(progStub)
      )
      .sort((w1, w2) => w1.name.localeCompare(w2.name));
</script>

<div class="cards">
  <div class="columns is-multiline">
    {#each groups as g}
      {#if workshops(g).length > 0}
        <div class="column">
          <div
            class="card"
            class:solo={groups.length == 1}
            style="background: #{colorsByGroup[g]}"
          >
            <h2>{groupFormatter(g)}</h2>
            <div class="buttons">
              {#each workshops(g) as w}
                <NavLink button buttonClass="is-rounded" to="/w/{w.stub}">
                  <span>{w.name.replace(" Workshop", "")}</span>
                  {#if icons(w)}
                    <Icon i={icons(w)} />
                  {/if}
                </NavLink>
              {/each}
            </div>
            {#if showAllWorkshops}
              <NavLink
                style="color:var(--ps-colors-dark-grey);font-size:var(--ps-fontsize-tiny)"
                to="/workshops">show all workshops</NavLink
              >
            {/if}
          </div>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
  .card {
    border-radius: 5px;
    height: 100%;
    padding: 1rem;
    width: 100%;
  }

  .card :global(.button) {
    background: #ffffffcc;
    color: var(--ps-colors-jet);
    font-family: var(--ps-fonts-body);
  }

  .card .buttons {
    margin-top: 1rem;
  }

  .card.solo .buttons {
    display: inherit;
  }

  .card h2 {
    font-size: var(--ps-fontsize-small);
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .card.solo h2 {
    text-align: center;
  }

  .card :global(.icon) {
    color: var(--ps-colors-mid-grey);
  }

  .cards {
    margin-top: 1.5rem;
  }

  .column {
    padding: 1rem;
  }
</style>
